import { Rating } from "../context/GlobalContextProvider"

export const averageRatings = (ratings: Rating[]) => {
  let sum = 0
  ratings.forEach(rating => {
    sum += rating.value
  })

  if (ratings.length === 0) return 0
  return Math.round((sum / ratings.length) * 100) / 100
}

export enum RatingProgress {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Complete = "complete",
}

// export const ratingsResponses = (ratings: number[]) => {
//   let totalRatings = 0
//   let completedRatings = 0

//   ratings.forEach(rating => {
//     if (rating.isRated) completedRatings += 1
//     totalRatings += 1
//   })

//   let progress: RatingProgress
//   if (totalRatings === completedRatings) progress = RatingProgress.Complete
//   else if (completedRatings === 0) progress = RatingProgress.NotStarted
//   else progress = RatingProgress.InProgress

//   return {
//     value: completedRatings + "/" + totalRatings,
//     progress,
//   }
// }
