import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/functions"
import "firebase/storage"

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyBCNL2d8nzSqQiT_Z_BrXctCsguheB_nBA",
  authDomain: "simplegrant-99c36.firebaseapp.com",
  databaseURL: "https://simplegrant-99c36.firebaseio.com",
  projectId: "simplegrant-99c36",
  storageBucket: "simplegrant-99c36.appspot.com",
  messagingSenderId: "597414091243",
  appId: "1:597414091243:web:7ac3a84244de4568ace1ae",
  measurementId: "G-CWQ8J0KZLV",
}

let instance: firebase.app.App | null = null

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }

  return null
}
