export const DefaultState: State = {
  projects: [],
  voteEvents: [],
  tableData: [],
  user: undefined,
  selectedProject: null,
  sidebar: {
    isCollapsed: false,
  },
  table: {
    showSubtitle: true,
    showRating: true,
    showStandardDeviation: true,
    showResponses: true,
  },
}

export interface Project {
  key: string
  name: string
  creatorUid: string
  users: string[]
  backgroundColor: string
  image?: string
}

export interface VoteEvent {
  key: string
  name: string
  author: string
  ratings: Rating[]
  names: NameInput[]
}

export interface TableData extends VoteEvent {
  rating: number
  responses: number
  standardDeviation: number
}

export interface Rating {
  value: number
  timestamp: number
}

export interface NameInput {
  value: string
  timestamp: number
}

export interface FirebaseUserData {
  name: string
  email: string
  uid: string
}

export type DashboardView = "project-list" | "project-view"

export interface State {
  voteEvents: VoteEvent[]
  tableData: TableData[]
  projects: Project[]
  user: FirebaseUserData | null | undefined
  sidebar: {
    isCollapsed: boolean
  }
  table: {
    showSubtitle: boolean
    showRating: boolean
    showStandardDeviation: boolean
    showResponses: boolean
  }
  selectedProject: Project | null
}
