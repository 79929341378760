import getFirebase from "../firebase/firebase"
import { v4 } from "uuid"
import { averageRatings } from "../helper/Ratings"
import {
  VoteEvent,
  TableData,
  Project,
  DashboardView,
  FirebaseUserData,
  DefaultState,
  State,
  NameInput,
} from "./State"
import firebaseImport from "firebase"
import { standardDeviation } from "simple-statistics"

export enum Actions {
  DeleteVoteEvent,
  DeleteResponseByName,
  DeleteProject,
  AddVoteEvent,
  AddProject,
  UpdateVoteEvents,
  UpdateProjects,
  ClearResponses,
  UserSignedIn,
  UserSignedOut,
  ToggleSidebar,
  SetSelectedProject,
  UpdateTableDisplay,
}

export interface ActionProps {
  type: Actions
  fields?: {
    value: string
  }[]
  voteEvent?: VoteEvent
  voteEvents?: VoteEvent[]
  tableData?: TableData[]
  table?: State["table"]
  name?: NameInput
  project?: Project
  projects?: Project[]
  user?: firebase.User
  key?: string
  dashboardView?: DashboardView
  image?: Blob
}

export type Reducer = (
  state: State,
  action: ActionProps
) => Promise<State | undefined>

const reducer: Reducer = async (state, action) => {
  const firebase = getFirebase()

  return new Promise(async resolve => {
    if (firebase) {
      switch (action.type) {
        case Actions.DeleteVoteEvent:
          if (action.key && state.selectedProject) {
            firebase
              .firestore()
              .collection("projects")
              .doc(state.selectedProject.key)
              .collection("proposals")
              .doc(action.key)
              .delete()
          }

          resolve(state)
          break

        case Actions.DeleteResponseByName:
          if (action.voteEvent && action.name && state.selectedProject) {
            const voteEventRef = firebase
              .firestore()
              .collection("projects")
              .doc(state.selectedProject.key)
              .collection("proposals")
              .doc(action.voteEvent.key)

            let nameToRemove
            let ratingToRemove

            action.voteEvent?.ratings.forEach(rating => {
              if (action.name?.timestamp === rating.timestamp) {
                nameToRemove = action.name
                ratingToRemove = rating
              }
            })

            if (nameToRemove) {
              voteEventRef.update({
                names: firebaseImport.firestore.FieldValue.arrayRemove(
                  nameToRemove
                ),
              })
            }

            if (ratingToRemove) {
              voteEventRef.update({
                ratings: firebaseImport.firestore.FieldValue.arrayRemove(
                  ratingToRemove
                ),
              })
            }
          }

          resolve(state)
          break

        case Actions.DeleteProject:
          if (!action.key) resolve(state)
          firebase.firestore().collection("projects").doc(action.key).delete()
          firebase
            .storage()
            .ref("images/" + action.key)
            .delete()
          resolve(state)
          break

        case Actions.AddVoteEvent:
          if (action.fields && state.selectedProject) {
            const proposal: VoteEvent = {
              key: v4(),
              name: action.fields[0].value,
              author: action.fields[1].value,
              ratings: [],
              names: [],
            }
            firebase
              .firestore()
              .collection("projects")
              .doc(state.selectedProject.key)
              .collection("proposals")
              .doc(proposal.key)
              .set(proposal)
          }
          resolve(state)
          break

        case Actions.AddProject:
          const { project, image } = action
          if (project && image) {
            const storageRef = firebase.storage().ref()
            const mountainImagesRef = storageRef.child("images/" + project.key)

            mountainImagesRef
              .put(image)
              .then(() => {
                firebase
                  .firestore()
                  .collection("projects")
                  .doc(project.key)
                  .set(project)
              })
              .catch(error => {
                console.error(error)
              })
          } else if (project) {
            firebase
              .firestore()
              .collection("projects")
              .doc(project.key)
              .set(project)
          }
          resolve(state)
          break

        case Actions.UpdateVoteEvents:
          if (action.voteEvents) {
            const tableData: TableData[] = action.voteEvents.map(voteEvent => {
              return {
                ...voteEvent,
                rating: averageRatings(voteEvent.ratings),
                responses: voteEvent.ratings.length,
                standardDeviation:
                  voteEvent.ratings.length > 0
                    ? Math.round(
                        standardDeviation(
                          voteEvent.ratings.map(rating => rating.value)
                        ) * 100000
                      ) / 100000
                    : 0,
              }
            })
            resolve({
              ...state,
              voteEvents: action.voteEvents,
              tableData,
            })
            break
          }
          resolve(state)
          break

        case Actions.UpdateProjects:
          const storage = firebase.storage()

          if (action.projects) {
            const projectPromise = action.projects.map(async project => {
              const ref = storage.ref("images/" + project.key)

              try {
                const url = await ref.getDownloadURL()
                return {
                  ...project,
                  image: url,
                }
              } catch (error) {
                console.log(error)
                return {
                  ...project,
                  image: null,
                }
              }
            })

            await Promise.all(projectPromise).then(projects => {
              resolve({
                ...state,
                projects,
              })
            })
            break
          }
          resolve(state)
          break

        case Actions.ClearResponses:
          if (!action.key || !state.selectedProject) resolve(state)
          firebase
            .firestore()
            .collection("projects")
            .doc(state.selectedProject?.key)
            .collection("proposals")
            .doc(action.key)
            .update({
              ratings: [],
              names: [],
            })
          resolve(state)
          break

        case Actions.UserSignedIn:
          if (action.user) {
            const userInformation = await firebase
              .firestore()
              .collection("users")
              .doc(action.user.uid)
              .get()

            if (!userInformation.exists) {
              resolve(state)
              break
            }

            resolve({
              ...state,
              user: userInformation.data() as FirebaseUserData,
            })
            break
          }
          resolve(state)
          break

        case Actions.UserSignedOut:
          resolve({ ...DefaultState, user: null })
          break

        case Actions.ToggleSidebar:
          resolve({
            ...state,
            sidebar: {
              isCollapsed: !state.sidebar.isCollapsed,
            },
          })
          break

        case Actions.SetSelectedProject:
          if (action.project || action.project === null) {
            if (action.project === null) {
              resolve({
                ...state,
                selectedProject: null,
                tableData: [],
              })
              break
            } else {
              resolve({
                ...state,
                selectedProject: action.project,
              })
              break
            }
          }
          resolve(state)
          break
        case Actions.UpdateTableDisplay:
          if (action.table) {
            return resolve({
              ...state,
              table: action.table,
            })
          }
        default:
          throw new Error("Invalid action in reducer.")
      }
    }
  })
}

export default reducer
