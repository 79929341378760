import React, { createContext, useEffect, useContext } from "react"
import useFirebase from "../firebase/useFirebase"
import useAsyncReducer from "./useAsyncReducer"
import reducer, { Actions, ActionProps } from "./reducer"
import { DefaultState } from "./State"

export const GlobalStateContext = createContext(DefaultState)
export const GlobalDispatchContext = createContext(
  (action: ActionProps): Promise<void> => {
    return new Promise(resolve => resolve())
  }
)
export type DispatchType = (action: ActionProps) => Promise<void>

const GlobalContextProvider: React.FC = ({ children }) => {
  const firebase = useFirebase()

  const [state, dispatch] = useAsyncReducer(reducer, DefaultState)

  useEffect(() => {
    if (!firebase || typeof window === undefined) return

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({ type: Actions.UserSignedIn, user })
      } else {
        dispatch({ type: Actions.UserSignedOut })
      }
    })
  }, [firebase])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
